import { playMedia, togglePlayPause } from "../../../../../assets/js/utils/playPauseMedia";

const audioClips = {};

function initAudio(el: HTMLElement, audioLinks: HTMLElement[]) {
  const url = el.getAttribute('href');

  if (!audioClips[url]) {
    audioClips[url] = new Audio(url);
  }

  el.addEventListener('click', (e) => {
    e.preventDefault();
    audioLinks.forEach((otherEl) => {
      if (otherEl !== el) {
        const otherUrl = otherEl.getAttribute('href');
        if (audioClips[otherUrl].paused === false) {
          togglePlayPause(audioClips[otherUrl]);
          audioClips[otherUrl].currentTime = 0;
        }
      }
    });

    playMedia(audioClips[url]);
  });
}

const audioLinks: HTMLElement[] = Array.from(document.querySelectorAll('.js-audio'));
audioLinks.forEach((el) => initAudio(el, audioLinks));

